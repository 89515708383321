import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

const swiperCategory = new Swiper('.swiper-shop-head', {
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 30,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});
const swiperShop = new Swiper('.swiper-shop', {
    spaceBetween: 30,
    breakpoints: {
        320: {
            slidesPerView: 1,
        },

        640: {
            slidesPerView: 2,
        },
        1040: {
            slidesPerView: 3,
        },
        1240: {
            slidesPerView: 4,
        },
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});