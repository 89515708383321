jQuery(document).ready(function ($) {
    const categorySlug = document.querySelectorAll('.filer-shop__li input');
    let categoriesSlugs = [];

    categorySlug.forEach(input => {
        input.addEventListener('change', () => {

            if (input.checked) {
                categoriesSlugs.push(input.value);
                $('#search-results').html('');
                $('#search-results').addClass('products-shop__content--all');
                $('#loader').show();
                $.ajax({
                    url: adminAJAX.ajaxurl,
                    method: 'POST',
                    data: {
                        action: 'search_products',
                        search: '',
                        categorySlug: categoriesSlugs
                    },
                    success: function (response) {
                        $('#search-results').html(response);
                    },
                    complete: function () {
                        $('#loader').hide();
                    }
                });
            } else {
                $('#search-results').html(initialProducts);
                $('#search-results').removeClass('products-shop__content--all');
                const index = categoriesSlugs.indexOf(input.value);
                if (index !== -1) {
                    categoriesSlugs.splice(index, 1);
                }

            }
            console.log(categoriesSlugs);
        });
    });

    var initialProducts = $('#search-results').html();

    $('#search-input').on('keyup', function () {
        var searchTerm = $(this).val();

        if (searchTerm.length > 5) {
            $('#search-results').html('');
            $('#search-results').addClass('products-shop__content--all');
            $('#loader').show();
            $.ajax({
                url: adminAJAX.ajaxurl,
                method: 'POST',
                data: {
                    action: 'search_products',
                    search: searchTerm
                },
                success: function (response) {
                    $('#search-results').html(response);
                },
                complete: function () {
                    $('#loader').hide();
                }
            });
        } else {
            $('#search-results').html(initialProducts);
            $('#search-results').removeClass('products-shop__content--all');
        }
    });

    var offset = 16;
    load_more_products();

    $('#load-more-btn').on('click', function () {
        let dots = $('.products-shop__dots');
        dots.addClass('active');
        $.ajax({
            url: adminAJAX.ajaxurl,
            method: 'POST',
            data: {
                action: 'load_more_products',
                offset: offset,
            },
            success: function (response) {
                dots.removeClass('active');
                $('.products-shop__content--all').append(response);
                offset += 16;
            }
        });
    });

    function load_more_products() {
        jQuery.ajax({
            url: adminAJAX.ajaxurl,
            method: 'POST',
            data: {
                action: 'load_more_products',
                offset: 0,
            },
            success: function (response) {
                $('.products-shop__content--all').html(response);
            }
        });
    }
});