export function doAJAXRequestCart(data, element) {
	(function ($) {
		$.ajax({
			url: adminAJAX.ajaxurl,
			method: 'POST',
			data: data,
			beforeSend: function () {},
			success: function (res) {
				switch (data.action) {
					case 'removeToCart':
						resRemoveToCart(res, element);
						break;

					default:
						break;
				}
			},
			error: function (error) {
				console.error(error);
			}
		});
	})(jQuery);
}

/**
 * Response function
 */

export function resRemoveToCart(res, element) {
	(function ($) {
		let cartSidebarCounter = $('.cart-sidebar__header__counter');
		let cartSidebarTotal = $('.cart-sidebar__footer__total');
		let cartSidebarContent = $('.cart-sidebar__content');
		let cartSidebarFooter = $('.cart-sidebar__footer');
		let cartIndicator = $('.cart-indicator');
		let miniCartCounter = document.querySelector('.mini-cart-icon');

		if (res.status) {
			element.remove();
			cartSidebarCounter.html(res.cart.quantity);
			if (res.cart.quantity != 0) {
				cartSidebarTotal.html(`
                    <strong>Total:</strong><span>$ ${res.cart.total.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                `);
			} else {
				cartSidebarContent.html(`
                    <p>Tu carrito está vacío</p>
                    <a href="/tienda-bienestar-asana"><button>Ir a la tienda</button></a>
                `);

				cartSidebarContent.addClass('empty');
				cartIndicator.removeClass('show');
				cartSidebarFooter.remove();
			}
			miniCartCounter.setAttribute('data-count', res.cart.quantity);
		}
	})(jQuery);
}
