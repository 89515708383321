import tippy from 'tippy.js';

(function ($) {
	$(document).ready(function () {
		const buttons = $('.viewed-classes__indicator');
		const addFavoriteButton = $('#add-favorite-class');
		const removeFavoriteButton = $('#remove-favorite-class');
		const classId = $('#class-id').val();

		tippy('.viewed-classes__indicator.unchecked', {
			content: 'Marcar clase'
		});

		buttons.click(function () {
			if ($(this).hasClass('unchecked')) {
				const id = $(this).data('id');
				doAJAXRequest({
					action: 'markClassView',
					id,
					classId
				});
			}
		});

		addFavoriteButton.click(function () {
			doAJAXRequest({
				action: 'changeFavoriteClass',
				type: 'add',
				classId
			});
		});

		removeFavoriteButton.click(function () {
			doAJAXRequest({
				action: 'changeFavoriteClass',
				type: 'remove',
				classId
			});
		});

		function doAJAXRequest(data) {
			$.ajax({
				url: adminAJAX.ajaxurl,
				method: 'POST',
				data: data,
				beforeSend: function () {
					switch (data.action) {
						case 'markClassView':
							$('.viewed-classes').html(
								'<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="1" y="4" width="6" height="14" opacity="1"><animate id="spinner_rQ7m" begin="0;spinner_2dMV.end-0.25s" attributeName="opacity" dur="0.75s" values="1;.2" fill="freeze"/></rect><rect x="9" y="4" width="6" height="14" opacity=".4"><animate begin="spinner_rQ7m.begin+0.15s" attributeName="opacity" dur="0.75s" values="1;.2" fill="freeze"/></rect><rect x="17" y="4" width="6" height="14" opacity=".3"><animate id="spinner_2dMV" begin="spinner_rQ7m.begin+0.3s" attributeName="opacity" dur="0.75s" values="1;.2" fill="freeze"/></rect></svg>'
							);
							break;

						case 'changeFavoriteClass':
							$('.favorite-classes').html(
								'<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="1" y="4" width="6" height="14" opacity="1"><animate id="spinner_rQ7m" begin="0;spinner_2dMV.end-0.25s" attributeName="opacity" dur="0.75s" values="1;.2" fill="freeze"/></rect><rect x="9" y="4" width="6" height="14" opacity=".4"><animate begin="spinner_rQ7m.begin+0.15s" attributeName="opacity" dur="0.75s" values="1;.2" fill="freeze"/></rect><rect x="17" y="4" width="6" height="14" opacity=".3"><animate id="spinner_2dMV" begin="spinner_rQ7m.begin+0.3s" attributeName="opacity" dur="0.75s" values="1;.2" fill="freeze"/></rect></svg>'
							);
							break;

						default:
							break;
					}
				},
				success: function (res) {
					window.location.reload();
				},
				error: function (error) {
					console.error(error);
				}
			});
		}
	});
})(jQuery);
