import Glide from '@glidejs/glide';

(function ($) {
	$(document).ready(function () {
		let carouselPromo = document.querySelector('.glide-carousel-promo');
		if (carouselPromo) {
			let glidePromo = new Glide(carouselPromo, {
				type: 'carousel',
				autoplay: 3000
			});
		}
	});
})(jQuery);
