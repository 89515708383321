import feather from 'feather-icons';
import { doAJAXRequestCart } from '../utils/cartFunctions';

export function addSubmitQuickView() {
	(function ($) {
		let submitQuickViewButton = $('.quick-view__actions__submit');
		submitQuickViewButton.click(function () {
			let productId = $(this).data('productId');
			let quantityInputValue = $(this).parent().children().children()[0].value;

			let AJAXRequestData = {
				action: 'addToCart',
				productId: productId,
				quantity: quantityInputValue
			};

			doAJAXRequest(AJAXRequestData);
		});
	})(jQuery);
}

export function createQuantityInput() {
	jQuery(
		`<div class="quantity-nav">
            <div class="quantity-button quantity-up">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                </svg>
            </div>
            <div class="quantity-button quantity-down">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
            </div>
        </div>`
	).insertAfter('.quantity input');

	jQuery('.quantity').each(function () {
		let spinner = jQuery(this),
			input = spinner.find('input[type="number"]'),
			btnUp = spinner.find('.quantity-up'),
			btnDown = spinner.find('.quantity-down'),
			min = input.attr('min'),
			max = input.attr('max');

		btnUp.click(function () {
			let oldValue = parseFloat(input.val());
			let newVal;
			newVal = oldValue + 1;

			spinner.find('input').val(newVal);
			spinner.find('input').trigger('change');
		});

		btnDown.click(function () {
			let oldValue = parseFloat(input.val());
			let newVal;
			if (oldValue <= min) {
				newVal = oldValue;
			} else {
				newVal = oldValue - 1;
			}
			spinner.find('input').val(newVal);
			spinner.find('input').trigger('change');
		});
	});
}

export function doAJAXRequest(data) {
	(function ($) {
		let modalContent = $('#modal-product-content');

		$(document).ready(function () {
			$.ajax({
				url: adminAJAX.ajaxurl,
				method: 'POST',
				data: data,
				beforeSend: function () {},
				success: function (res) {
					switch (data.action) {
						case 'addToCart':
							resAddToCart(res, modalContent);
							break;

						case 'getProductData':
							resGetProductData(res, modalContent);
							break;

						default:
							break;
					}
				},
				error: function (error) {
					console.error(error);
				}
			});
		});
	})(jQuery);
}

/**
 * Response Functions
 */

function resAddToCart(res, modalContent) {
	(function ($) {
		if (res) {
			if (res.status) {
				let html = `
					<div class="add-to-cart">
						<div class="add-to-cart__left">
							<p>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
									<path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
								</svg>
								<span>Producto agregado al carrito exitosamente</span>
							</p>
							<img src="${res.product.image}" alt="Imagen destacada del producto" class="add-to-cart__left__image"/>
							<div class="add-to-cart__left__info">
								<p class="add-to-cart__left__info__name">${res.product.name}</p>
								<p class="add-to-cart__left__info__meta"><strong>Precio: </strong><span> $${res.product.price
									.toString()
									.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span></p>
								<p class="add-to-cart__left__info__meta"><strong>Cantidad: </strong><span>${res.cart.product_quantity}</span></p>
								<p class="add-to-cart__left__info__meta"><strong>Total: </strong><span> $${res.cart.product_total
									.toString()
									.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span></p>
							</div>
						</div>
						<div class="add-to-cart__right">
							<p>Hay <span class="highlight">${res.cart.quantity}</span> productos en su carrito</p>
							<p class="add-to-cart__right__info__meta"><strong>Total: </strong><span> $${res.cart.total
								.toString()
								.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span></p>
							<button class="add-to-cart__right__close">Continuar comprando</button>
							<a href="/proceder-con-el-pago"><button class="add-to-cart__right__cart">Ir al carrito</button></a>
						</div>
					</div>
				`;

				modalContent.html(html);
				MicroModal.show('modal-product');
				$('.add-to-cart__right__close').click(function () {
					MicroModal.close();
				});

				renderProductsCartSidebar(res, true);
			}
		}
	})(jQuery);
}

function resGetProductData(res, modalContent) {
	(function ($) {
		if (res) {
			let htmlThumbnails = '';

			if (res.gallery.length) {
				res.gallery.forEach((image) => {
					htmlThumbnails += `
						<img src="${image}" alt="Imagen de la galería del producto"/>
					`;
				});
			}

			let html = `
				<div class="quick-view">
					<div class="quick-view__gallery">
						<a href="${res.permalink}">
							<img src="${res.image}" alt="Imagen destacada del producto" class="quick-view__gallery__image"/>
						</a>
						<div class="quick-view__gallery__thumbnails">
							${htmlThumbnails ? htmlThumbnails : `<img src="${res.image}" alt="Imagen destacada del producto"/>`}
						</div>
					</div>
					<div class="quick-view__content">
						<h2><a href="${res.permalink}">${res.name}</a></h2>
						<p class="quick-view__content__price">${res.price}</p>
						<div class="quick-view__content__description">${res.description}</div>
						<div class="quick-view__actions">
							<div class="quantity">
								<input type="number" min="1" value="1" class="quick-view__actions__input"/>
							</div>
							<button class="quick-view__actions__submit" data-product-id=${res.id}>Agregar al carrito</button>
						</div>
					</div>
				</div>
			`;

			modalContent.html(html);
			addSubmitQuickView();
			createQuantityInput();
			MicroModal.show('modal-product');

			let thumbnails = $('.quick-view__gallery__thumbnails img');
			let image = $('.quick-view__gallery__image');

			thumbnails.click(function () {
				image.attr('src', $(this).attr('src'));
			});
		}
	})(jQuery);
}

export function renderProductsCartSidebar(res, deleteEnabled) {
	(function ($) {
		let cartSidebarCounter = $('.cart-sidebar__header__counter');
		let cartSidebarTotal = $('.cart-sidebar__footer__total');
		let cartSidebarContent = $('.cart-sidebar__content');
		let cartSidebarFooter = $('.cart-sidebar__footer');
		let cartIndicator = $('.cart-indicator');
		let htmlCartSidebar = '';

		if (res.products) {
			res.products.forEach((product) => {
				htmlCartSidebar += `
					<div  class="cart-sidebar__content__item">
						<a href="${product.permalink}">
							<img 
								src="${product.image}" 
								alt="Imagen del producto"
							>
						</a>
						<div class="cart-sidebar__content__item__info">
							<a href="${product.permalink}">${product.name}</a>
							<p>Cantidad: ${product.quantity}</p>
							<p class="cart-sidebar__content__item__info__price">${product.total
								.toString()
								.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</p>
	
							${
								deleteEnabled
									? `
										<button class="cart-sidebar__content__item__info__delete" data-cart-item-key="${product.key}">
											<i data-feather="trash-2"></i>
										</button>
									`
									: ''
							}
						</div>
					</div>
				`;
			});

			cartSidebarContent.html(htmlCartSidebar);
			cartSidebarContent.removeClass('empty');
			cartIndicator.addClass('show');
		}

		cartSidebarCounter.html(res.cart.quantity);

		if (res.cart.quantity != 0) {
			cartSidebarTotal.html(`
				<strong>Total:</strong><span>$ ${res.cart.total.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
			`);

			if (!cartSidebarFooter.length) {
				let htmlCartSidebarFooter = `
				<div class="cart-sidebar__footer">
					<div class="cart-sidebar__footer__total">
						<strong>Total:</strong><span>$ ${res.cart.total.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
					</div>
					<div class="cart-sidebar__footer__actions">
						<a href="/proceder-con-el-pago"><button class="cart-sidebar__footer__actions__cart">Ver carrito</button></a>
						<a href="/resumen-de-compra"><button class="cart-sidebar__footer__actions__checkout">Ir al checkuot</button></a>
					</div>
				</div>
				`;

				$(`${htmlCartSidebarFooter}`).insertAfter(cartSidebarContent);
			}
		} else {
			cartSidebarContent.html(`
				<p>Tu carrito está vacío</p>
				<a href="/shop"><button>Ir a la tienda</button></a>
			`);

			cartSidebarContent.addClass('empty');
			cartIndicator.removeClass('show');
			cartSidebarFooter.remove();
		}

		if (deleteEnabled) {
			feather.replace();

			let deleteCartSidebarButton = $('.cart-sidebar__content__item__info__delete');

			deleteCartSidebarButton.click(function () {
				let wrapperElement = $(this).parent().parent();
				let cartItemKey = $(this).data('cartItemKey');

				let AJAXRequestData = {
					action: 'removeToCart',
					cartItemKey: cartItemKey
				};
				doAJAXRequestCart(AJAXRequestData, wrapperElement);
			});
		}
	})(jQuery);
}
