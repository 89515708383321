(function ($) {
    $(document).ready(function () {

        if (document.querySelector('#remaining')) {
            const myAccountWc = document.querySelector('.woocommerce-MyAccount-navigation ul');
            const myAccountWcLi = myAccountWc.querySelectorAll('li');
            const myAcountMembershep = document.querySelector('#remaining');
            const myAccountContent = document.querySelector('.woocommerce-MyAccount-content');
            const myAcountMembershepContent = document.querySelector('.remaining__content');
            const lastItem = myAccountWc.lastElementChild;
            myAccountWc.insertBefore(myAcountMembershep, lastItem);

            myAcountMembershep.addEventListener('click', () => {
                myAcountMembershep.classList.add('is-active');
                myAccountWcLi.forEach(item => {
                    if (item !== myAccountContent) {

                        if (item.classList.contains('is-active')) {
                            item.classList.remove('is-active');
                        }
                    }

                })
                myAccountContent.innerHTML = "";
                myAccountContent.appendChild(myAcountMembershepContent);
            });
        }

    });
})(jQuery);