(function ($) {
	$(document).ready(function () {
		let isPurchasedFreeMembership = $('#is-purchased-free-membership').val();
		let currentLocation = window.location.pathname;
		let promoProductID = $('#promo-product-id').val();
		let promoProduct = $(`.grid-item.type-product.post-${promoProductID}`);
		let promoProductForm = $(`.type-product.post-${promoProductID} form`);
		let promoButton = $('#modal-promo-button');
		let promoButtonClose = $('#modal-promo-button .modal__close');

		if (
			!isPurchasedFreeMembership &&
			(currentLocation === '/' ||
				currentLocation === '/tienda-bienestar-asana/' ||
				currentLocation === '/product-category/membresias-clases-yoga/')
		) {
			// MicroModal.show('modal-promo');
			setTimeout(() => {
				promoButton.css('bottom', '40px');
			}, 1000);

			promoButtonClose.click(function () {
				promoButton.css('bottom', '-300px');
			});

			setTimeout(() => {
				promoButton.css('bottom', '-300px');
			}, 9000);
		}

		if (isPurchasedFreeMembership) {
			promoProduct.remove();
			promoProductForm.html('<p>Ya has adquirido este producto</p>');
			promoProductForm.css('display', 'block');
		}
	});
})(jQuery);
