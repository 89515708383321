import { SidebarElement } from 'sidebarjs';
import { doAJAXRequestCart } from '../utils/cartFunctions';

(function ($) {
	$(document).ready(function () {
		const cartSidebar = new SidebarElement({
			position: 'right'
		});

		let cartSidebarMarkup = $('.cart-sidebar');
		let toogleCartSidebarButton = $('.cart-sidebar__toogle');
		let closeCartSidebarButton = $('.cart-sidebar__header__close');
		let deleteCartSidebarButton = $('.cart-sidebar__content__item__info__delete');

		cartSidebarMarkup.css('display', 'unset');

		closeCartSidebarButton.click(function () {
			cartSidebar.close();
		});

		toogleCartSidebarButton.click(function () {
			cartSidebar.toggle();
		});

		deleteCartSidebarButton.click(function () {
			let wrapperElement = $(this).parent().parent();
			let cartItemKey = $(this).data('cartItemKey');

			let AJAXRequestData = {
				action: 'removeToCart',
				cartItemKey: cartItemKey
			};
			doAJAXRequestCart(AJAXRequestData, wrapperElement);
		});
	});
})(jQuery);
