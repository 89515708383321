import feather from 'feather-icons';
import MicroModal from 'micromodal';

import './components/carouselPromo';
import './components/cartSidebar';
import './components/countDown';
import './components/modalPromo';
import './components/myAccount';
import './components/productListener';
import './components/slidesSwiper';
import './components/viewedClasses';

import './layout/header';
import './pages/index';
window.onload = function () {

	let searchBar0 = document.querySelector('#woocommerce-product-search-field-0');
	let searchBar1 = document.querySelector('#woocommerce-product-search-field-1');

	if (searchBar0) {
		searchBar0.setAttribute('placeholder', 'Buscar productos...');
		searchBar0.style.display = 'unset';
	}

	if (searchBar1) {
		searchBar1.setAttribute('placeholder', 'Buscar productos...');
		searchBar1.style.display = 'unset';
	}

	let productCount = document.querySelector('.woocommerce-result-count');

	if (productCount) {
		let productCountNumber = document.querySelector('.woocommerce-result-count .total').innerHTML;
		productCount.innerHTML = `Hemos encontrado <span class="total">${productCountNumber}</span> disponibles para ti`;
		productCount.style.display = 'unset';
	}

	let messageOnlyOneProduct = document.querySelector('.woocommerce-info.wc-nonpurchasable-message');

	if (messageOnlyOneProduct) {
		messageOnlyOneProduct.innerHTML = `Solo puedes agregar una membresía a tu <a href="/proceder-con-el-pago/" title="Cart">Carrito</a>.`;
		messageOnlyOneProduct.style.display = 'block';
	}

	let iframe = document.querySelector('iframe');

	if (iframe) {
		if (iframe.getAttribute('height') === '360') {
			iframe.setAttribute('width', '');
			iframe.setAttribute('height', '');
		}
	}

	feather.replace();
	MicroModal.init();
};
