import { doAJAXRequest } from '../utils/productFunctions';

(function ($) {
	$(document).ready(function () {
		/**
		 * Event Listeners
		 */

		let addToCartButton = $('.ajax_add_to_cart');

		addToCartButton.click(function () {
			let productId = $(this).data('product_id');

			let AJAXRequestData = {
				action: 'addToCart',
				productId: productId
			};
			doAJAXRequest(AJAXRequestData);
		});
	});
})(jQuery);
