import './simplyCountdown';

if (document.querySelector('.pop-up-end')) {
    const countdownDate = document.querySelector('.pop-up-end__date').value;
    const dateObject = new Date(countdownDate);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    const hour = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const popUpDiscount = document.querySelector('.pop-up-end');
    const popUpClose = popUpDiscount.querySelector('.pop-up-end__close');

    if (localStorage.getItem("isOpener") !== "true") {
        setTimeout(() => {
            popUpDiscount.classList.add('open');
        }, 5000);
    }

    popUpClose.addEventListener("click", () => {
        popUpDiscount.classList.remove('open');
        localStorage.setItem("isOpener", "true");

        setTimeout(() => {
            localStorage.setItem("isOpener", "false");
        }, 10000);
    });


    simplyCountdown('#counter', {
        year: year, // required
        month: month, // required
        day: day, // required
        hours: hour, // Default is 0 [0-23] integer
        minutes: minutes, // Default is 0 [0-59] integer
        seconds: 0, // Default is 0 [0-59] integer
        words: { //words displayed into the countdown
            days: { singular: 'dia', plural: 'dias' },
            hours: { singular: 'hora', plural: 'horas' },
            minutes: { singular: 'minuto', plural: 'minutos' },
            seconds: { singular: 'segundo', plural: 'segundos' }
        },
        plural: true, //use plurals
        inline: false, //set to true to get an inline basic countdown like : 24 days, 4 hours, 2 minutes, 5 seconds
        inlineClass: 'simply-countdown-inline', //inline css span class in case of inline = true
        // in case of inline set to false
        enableUtc: false, //Use UTC or not - default : false
        onEnd: function () { return; }, //Callback on countdown end, put your own function here
        refresh: 1000, // default refresh every 1s
        sectionClass: 'simply-section', //section css class
        amountClass: 'simply-amount', // amount css class
        wordClass: 'simply-word', // word css class
        zeroPad: false,
        countUp: false
    });

}

